/* You can add global styles to this file, and also import other style files */
.title-page{
  color: white;
  margin: 15vh auto 0;
  transform: translateY(-50%);
  text-align: center;

  h1{
    font-size: 50px;
  }
}

.list-cards{
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 100px;
  padding-bottom: 2.5rem;

  @media screen and (max-width: 935px){
    margin-top: 10%;
  }
  p-card{
    margin-bottom: 15px;
  }
}

h1, h2, p{
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

